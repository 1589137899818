// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ONLINE_TRAINING: 'Digital Ridesum training',
  PLEASE_SUMMARIZE:
    "This session can be recorded. Please note that you are responsible for informing persons who appear in the background that recording may occur when using your webcam. More information about Ridesum's processing of personal data can be found on our website.",
  WRITE_FEEDBACK: 'Write feedback',
  FEEDBACK_TEMPLATE: `•	What was positive?
•	What can be improved and how?
•	Tips about exercises?
•	More boost!`,
  RIDER: 'Rider',
  MESSAGE: 'Message',
  TRAINING_IS_PAUSED: 'The training is paused',
  CONTINUE_TRAINING: 'Continue training',
  FINISH_TRAINING: 'Finish training',
  FEEDBACK_NOTES: 'Feedback notes',
  SESSION_EXPIRED: 'Session expired',
  YOUR_FEEDBACK: 'Your feedback',
  CREATE_AUDIO_FEEDBACK_IN_APP: 'You can create voice feedback directly and better in the mobile application.',
  WRITE_FEEDBACK_TO_RIDER_HERE: 'Write your feedback to the rider here.',
  SEND: 'Send',
  BACK: 'Back',
  FEEDBACK: 'Video',
  TRAINING: 'Training',
  LINK_TO_VIDEO: 'Link to video',
  DEADLINE: 'Deadline',
  TIME: 'Time',
  DURATION: 'Duration',
  START_TRAINING: 'Start training',
  YOU_CAN_SAVE_AND_SEND_LATER: 'You can save the feedback, edit and send later if you want.',
  SAVE: 'Save',
  EMAIL: 'E-mail',
  EMAIL_REQUIRED: 'Email is required',
  CONFIRM_EMAIL: 'Confirm email',
  PASSWORD: 'Password',
  PASSWORD_REQUIRED: 'Password is required',
  REMEMBER_ME: 'Remember me',
  FORGOT_PASSWORD_IN_MOBILE_APP: 'Forgot password? Go to the mobile app login screen and use "Forgot password" there.',
  ERROR: 'Error',
  LOGIN_ERROR: 'Wrong email or password',
  SERVER_ERROR: 'Server error',
  UNEXPECTED_ERROR: 'An unexpected error occurred.',
  OK: 'Ok',
  TRY_AGAIN: 'Try again',
  YOUR_MESSAGE_SENT: 'Thanks! Your message has been sent!',
  RIDER_NOTIFIED: 'Rider notified.',
  YOUR_FEEDBACK_SENT: 'Awesome! Your feedback has been sent.',
  LOGOUT: 'Logout',
  SEE_ALL: 'See all',
  SEE_LESS: 'See less',
  RIDER_MESSAGE: "Rider's message",
  CUSTOMER_MESSAGE: "Customer's message",
  TRAINER_MESSAGE: "Trainer's message",
  MY_MESSAGE: 'My message',
  IMPORTANT: 'Important',
  YOU_MUST_ACCEPT_REQUEST_IN_MOBILE:
    'You need to accept the booking request in the mobile app in order to start digital live training or write feedback on pre-recorded video here.',
  BROWSER_CHROME: 'Google Chrome',
  BROWSER_SAFARI: 'Safari',
  WARNING_MESSAGE: 'For a better experience with Ridesum, we highly recommend using Google',
  CONTINUE: 'Continue',
  LOGIN: 'Log in',
  LOGIN_SHORT_SLOGAN: 'Take the reins and join Ridesum today!',
  LOGIN_LONG_SLOGAN:
    'Ridesum is your perfect business buddy, you get more visibility and get access to smart training & business tools such as booking system and invoicing. Work smarter, save time & scale your business!',
  GET_STARTED_TODAY_LABEL: 'Get started today!',
  TRAINING_TYPE: {
    traditional_training: 'Face-to-face',
    training: 'Digital',
    feedback: 'Feedback',
  },
  RIDESUM_SLOGAN: 'Training anywhere, anytime',
  REGISTRATION_SUGGESTION:
    "Don't have an account? Please register one in the Ridesum mobile app. Download in App Store or Google Play.",
  UPCOMING: 'Upcoming',
  CALENDAR: 'Calendar',
  NO_ACTIVITIES:
    "Currently, you don't have any pending or accepted booking requests. After you receive one, it will be displayed on this page.",
  PENDING_REQUESTS: 'Pending booking requests',
  RESPONSE_DATE: 'Response date',
  LOCATION: 'Location',
  SESSION_ID: 'Session ID',
  COPY_SESSION_ID: 'Copy Session ID',
  NO_ACTIVITIES_REGISTERED: 'No activities registered',
  REQUEST_DATE: 'Request date',
  REDEEM_CODE: 'Redeem a code',
  VERIFY_CODE: 'Verify',
  PROMOCODE_INSTRUCTIONS: `1. Create a profile in the app - enter your registered e-mail
  2. Redeem the code and choose if you are rider or trainer
  3. The offer is now registered on your Ridesum account`,
  PROMOCODE_SERVER_ERROR: {
    invalid_promocode: 'The promocode is not active',
    user_not_exist: 'User does not exist (Register email in app first)',
  },
  PROMOCODE_APPLIED: 'Promocode successfully applied',
  PROMOCODE_REQUIRED: 'Promocode is required',
  PREMIUM_SETTINGS: 'Premium settings',
  PROMOCODE: 'Promocode',
  APPLY_PROMOCODE: 'Apply',
  DISCOUNT: 'Discount',
  PROMOCODE_DETAILED_TITLE:
    '%{id} (%{productTitle} for %{durationMonth} month(s), valid from %{validFrom} to %{validTo})',
  BOOKINGS: 'Activities',
  CONNECTED_RIDERS: 'Connected Users',
  NAME: 'Name',
  AGE: 'Age',
  STUDENT_SINCE: 'Student since',
  PHONE: 'Phone',
  YEAR: 'year',
  CONNECTED_RIDERS_TO: 'Connected riders to',
  CONNECTED_TRAINERS_TO: 'Connected trainers to',
  REQUESTS: 'Requests',
  RIDERS_MESSAGE: "Rider's message",
  TRAINERS_MESSAGE: "Trainer's message",
  START: 'Start',
  FAILED: 'Failed',
  DONE: 'Done',
  WAITING: 'Waiting',
  TODAY: 'Today',
  PENDING: 'Pending',
  ACTIVITIES_OF: 'Activities of',
  ACCEPTED: 'Accepted',
  CANCEL_OR_NEW_TIME: 'Cancel/New date',
  SUGGEST_SEVERAL_DATES: 'Please suggest several new dates if requested date didn`t suit your schedule',
  ACCEPT: 'Accept',
  ACCEPT_REQUEST: 'Accept request',
  ACCEPT_MESSAGE_PROMPT: 'Here you can write a message to the rider, i.e. confirm the price.',
  WRITE_MESSAGE: 'Write a message…',
  FEEDBACK_ON_VIDEO: 'Feedback on video',
  RIDESUM_TRAINING: 'Ridesum-training',
  SHARE_FEEDBACK_WITH_PAYMENTS: 'Share feedback with payments',
  CANCEL: 'Cancel',
  INVOICE_SENT_TITLE: 'Invoice is sent',
  INVOICE_SENT_SUCCESS_1: 'Your invoice is sent to the rider. The rider gets a notification & can pay with one click.',
  INVOICE_SENT_SUCCESS_2: 'You will find your invoice under Premium in the mobile app.',
  TRIGGER_PAYMENT: 'Trigger Payment',
  TRIGGER_PAYMENT_ERROR:
    "It looks like you're missing some information, for us to be able to send invoices. Please open the app and go to payments.",
  INCLUDE_VAT: 'incl VAT',
  TRAINER: 'Trainer',
  PREMIUM: 'Premium',
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  CLOSE: 'Close',
  SESSIONS_HISTORY: 'Sessions History',
  STATUS: 'Status',
  NEWLY_ADDED_TRAINER_REVIEW_STATUS: 'Newly added',
  APPROVED_TRAINER_REVIEW_STATUS: 'Approved',
  REJECTED_TRAINER_REVIEW_STATUS: 'Rejected',
  TRAINER_NAME: 'Trainer name: {trainerName}',
  PUBLIC_UPLOADS: 'Profile materials',
  TRAINER_REVIEWS: 'Reviews ({numberOfReviews})',
  ALL_TRAINER_REVIEWS: 'All trainer reviews',
  SEARCH_TRAINER_REVIEW: 'Search trainer review',
  NEW_TRAINER_REVIEWS: 'New Reviews',
  NO_SEARCH_REVIEWS_FOUND: 'No recommendation have been found for this request.',
  NO_TRAINER_REVIEWS_ADDED: 'No recommendation has been left for the trainer',
  AVERAGE_RATING: 'Average rating',
  TRAINER_REVIEW_DETAILS: 'Trainer review details',
  TRAINER_REVIEW_DETAILS_TITLE: 'Recommendation about trainer {trainerName} has been left by rider {authorName}.',
  TRAINER_REVIEW_FIRST_QUESTION: 'Would you recommend {trainerName} to other Ridesum users?',
  TRAINER_REVIEW_SECOND_QUESTION: 'How engaged is {trainerName} in your development?',
  TRAINER_REVIEW_THIRD_QUESTION: 'How focused was {trainerName} during your session?',
  TRAINER_REVIEW_FOURTH_QUESTION: 'Do you feel that {trainerName} helps you develop?',
  TRAINER_TEXT_FEEDBACK_LABEL: 'Text feedback',
  RIDER_LEFT_ANONYMOUS_REVIEW: 'The user preferred to leave an anonymous review',
  APPROVE: 'Approve',
  REJECT: 'Reject',
  DELETE_USER: 'Delete user',
  SEARCH_USER: 'Search user',
  SEARCH_BY: 'Search by',
  SEARCH: 'Search',
  PHONENR: 'Phone',
  ADD: 'Add',
  ACCOUNT_STATUS: 'Account status',
  LAST_LOGIN_TIME: 'Last login time',
  WEBSITE: 'Website',
  CITY: 'City',
  DISTRICT: 'District',
  COMPETENCES: 'Competences',
  ADD_COMPETENCE: 'Add competences',
  SUM: 'Summary',
  PRICES: 'Prices',
  PRICE: 'Price',
  PRICE_45_MIN: '45 min digital training',
  ENTER_PRICE_TITLE: 'Length & training type',
  SAVE_CHANGES: 'Save changes',
  EDIT: 'Edit',
  COUNTRY: 'Country',
  SEARCHFOR: 'Search for...',
  DECLINED: 'Declined',
  NO_USER_FOUND: 'Neigh! No one was found here! Try again.',
  COPY_USER_ID: 'Copy user ID',
  PLUS: 'PLUS',
  CANCELLED: 'Cancelled',
  TRAINERS: 'Trainers',
  RIDERS: 'Riders',
  ID: 'id',
  REGISTRATION_DATE: 'Registration Date',
  ADDRESS: 'Address',
  COPY_SESSION_ID_SUCCESS_TITLE: 'Sessions ID is copied!',
  COPY_SESSION_ID_SUCCESS_TEXT: 'Congratulations! You have just successfully copied the Sessions ID to your computer.',
  ZIP_CODE: 'Zip code',
  EDIT_USER: 'Edit User',
  ADMIN_COMMENT_REPORTER: 'Admin Reporter',
  ADMIN_COMMENT_MESSAGE: 'Reported Comment',
  ADMIN_COMMENTS: 'Admin Comments',
  ADD_COMMENT: 'Add Comment',
  ADD_NAME: 'Add name',
  ADD_MESSAGE: 'Add Message',
  REPORTER_NAME: 'Reporter',
  ERROR_BOTH_FIELDS: 'Comment must be filled',
  INVOICE_MESSAGE: 'Invoice Message',
  INVOICE_MESSAGE_PLACEHOLDER: `Optional message connected to your invoice.
Character limit: 255`,
  COMPANY: 'Company',
  ORGANIZATIONNUMBER: 'Org. nr.',
  STREAMING_MINUTES: 'Streaming minutes',
  RECORDING_MINUTES: 'Recording minutes',
  MIN_SPENT: 'min spent of ',
  MIN_AVAILABLE: 'min available this period',
  UNLIMITED_STREAMING: 'Unlimited streaming',
  UNLIMITED_RECORDING: 'Unlimited recording',
  RECORDING_UNAVAILABLE: 'Recording is unavailable',
  CREDIT_CARD: 'Credit Card',
  YES: 'Yes',
  NO: 'No',
  TRAINER_MESSAGES: [
    {
      title: 'Do you want to save time & work smarter?',
      text: [
        'Do as many reputable trainers – use Pocket Office for your business! Invoicing, automatic payment reminders & everything about your customers & bookings saved in one place.',
        'Efficient, smooth & increased control!',
      ],
      link: 'https://www.ridesum.com/trainers/',
      linktitle: 'Learn more here',
    },
    {
      title: 'Tips for online training:',
      text: [
        '1. Zoom by clicking on plus icon at bottom right of the screen.',
        '2. Do you want to show something? Start by clicking on camera icon. Rider need to click on “coach icon”.',
        '3. Maximize your screen by clicking on the “square icon”.',
      ],
      link: 'http://support.ridesum.com/',
      linktitle: 'Learn more on our Support site',
    },
  ],
  RATE_YOUR_SESSION: 'Rate your session',
  SESSION_DETAILS: 'Session details',
  HOW_WAS_THE_VIDEO_QUALITY: 'How was the video quality?',
  HOW_WAS_THE_SOUND_QUALITY: 'How was the sound quality?',
  ENTER_YOUR_COMMENT: 'Enter your comment (optional)',
  DATE: 'Date',
  TRAINING_TYPE_LABEL: 'Training type',
  ESTABLISHING_CONNECTION: 'Connecting...',
  TRAINER_STREAM: {
    NOBODY_CONNECTED: 'No one has connected to the session yet',
    NOBODY_CONNECTED_INSURANCE: 'No one has connected to the session yet',
    CUSTOMER: 'Customer',
    MESSAGE_TO_CUSTOMER: 'Message to customer',
  },
  TRAINER_PRE_CALL: {
    TITLE: 'Before you connect, check your setup',
    MICROPHONE: 'Microphone',
    CAMERA: 'Camera',
    BACK_CAMERA: 'Back camera',
    FRONT_CAMERA: 'Front camera',
    CONNECTIVITY_TEST: 'Connectivity test',
    AUDIO_QUALITY: 'Audio quality',
    VIDEO_QUALITY: 'Video quality',
    JOIN_SESSION: 'Join session',
    EXCELENT_QUALITY: 'Excellent',
    GOOD_QUALITY: 'Good',
    FAIR_QUALITY: 'Fair',
    POOR_QUALITY: 'Poor',
    BAD_QUALITY: 'Bad',
    ERRORS: {
      CAMERA_DISABLED:
        'Please allow access to the camera and/or the microphone for app.ridesum.com and then refresh the page to be able to start your session. Happy training!',
      NO_CAMERA:
        'Make sure a camera and/or microphone are plugged in and then refresh the page to be able to start your session. Happy training!',
      DEVICE_IN_USE:
        'It looks like the camera and/or microphone are in use by another browser tab or application. Try closing other tabs or restarting the computer.',
      CONSTRAINTS_NOT_FOUND:
        'Unable to find camera and/or microphone suitable for the session. Try using another web browser.',
    },
  },
  FOOTER: {
    HOME: 'HOME',
    TRAINER: 'TRAINER',
    RIDER: 'RIDER',
    NEWS: 'NEWS',
    SUPPORT: 'SUPPORT',
  },
  ADMIN: {
    EDIT_USER: {
      GRANT_PREMIUM: 'Grant premium',
      PREMIUM_PRODUCT: 'Premium product',
      PREMIUM_UNTIL: 'Premium until (inclusively)',
      GRANT_UNTIL: '%{product} until %{date}',
    },
    COURSES: {
      COURSES_PAYMENTS: 'Courses payments',
      FILTER_BY_USER: 'Filter by user',
      GIVE_COURSE: 'Give course',
      NO_COURSES_FOUND: 'No courses found',
      RIDER_OWNS_ALL_COURSES: 'Rider owns all courses',
      PAYMENT_DETAILS: 'Course payment details',
      PAYMENT: 'Payment',
      CUSTOMER: 'Customer',
      COURSE: 'Course',
      PAYMENT_TYPE: 'Payment type',
      STRIPE_PAYMENT: 'Stripe payment intent',
      CHARGED_PRICE: 'Charged',
      PROMOCODE: 'Promocode',
      SERVICE_FEE: 'Service fee',
      PAYMENT_START: 'Payment began',
      PAYMENT_UPDATE: 'Last update',
      PAYMENT_COMPLETE: 'Paid',
      WATCHED_LESSONS: 'Watched lessons',
      GIFT_CARD: 'Gift card',
      GIFT_CARD_CODE: 'Activation code',
      GIFT_CARD_BUYER: 'Gift card buyer',
      FREE: 'Free',
      SWISH_PAYMENT_ATTEMPTS: 'Swish payment attempts',
      PAYMENT_STATUS: {
        DONE: 'Paid (user can watch the course)',
        FAIL: "Failed (payment didn't went through)",
        NEW: 'New (payment not confirmed)',
        WAIT: 'Processing',
      },
    },
  },
  SEARCH_SCREEN: {
    SEARCH_YOUR_TRAINER: 'Find your trainer',
    ENTER_TRAINER_NAME: 'Search trainer/expert by name',
    SHOW_RESULTS_NEAR: 'Show results near me first',
    HIGHLIGHTED_TRAINERS: 'Highlighted trainers',
    MORE_TRAINERS: 'More trainers/experts',
    COUNTRIES: 'Countries',
    DISTRICTS: 'Districts',
    LANGUAGES: 'Languages',
    COMPETENCES: 'Competences',
    CATEGORIES: 'Categories',
    SEE_PROFILE: 'See profile',
    BOOK_TRAINER: 'Book trainer/expert',
    PRICE: 'Price',
    LENGTH_AND_TRAINING_PRICE: 'Length and price',
    SKILLS: 'Skills',
    PER_45_MIN: 'Per 45 minutes',
    PER_X_MIN: 'Per %{minutes}',
    SEARCH: 'Search',
    MORE: '+more',
    SHOW_MORE: 'Show more',
    LIABILITY: 'Liability insurance',
    TAX_CERTIFICATE: 'Tax certificate',
    UNKNOWN: 'Unknown',
    HIDE: 'Hide',
    SHOW_ALL: 'Show all',
    TRAINER_EXPERT: 'Trainer/Expert',
    HAVE_NO_WRITTEN: 'The trainer/expert have not written any summary',
    FILTER: 'Filter',
    NO_CURRENCY_FILTER: 'Any currency',
    CLEAR_FILTER: 'Clear filter',
    CLEAR_FILTER_CONFIRMATION: 'Are you sure you want to clear filter?',
    CLEAR_ALL_FILTERS: 'Clear all filters',
    CLEAR_ALL_FILTERS_CONFIRMATION: 'Are you sure you want to clear all filters?',
  },
  CATEGORY_SCREEN: {
    VIDEOS: 'Inspirational videos',
    ERROR_MESSAGE: 'Sorry! Something went wrong and we could not get the category information. Please try again.',
  },
  SEARCH_RESULT_SCREEN: {
    TRAINERS: 'Search results',
    NO_RESULTS: "Sorry, we didn't find any results. But here are some of our top trainers",
  },
  TRAINER_PROFILE_SCREEN: {
    ERROR_MESSAGE: 'Sorry! Something went wrong and we could not get the trainer information. Please try again.',
  },
  WEB_TRAINERS: {
    HEADER: {
      HOME: 'HOME',
      TRAINERS: 'TRAINERS',
      TRAINERS__RIDESUM_FOR_RIDERS: 'RIDESUM FOR TRAINERS',
      TRAINERS__POCKET_OFFICE: 'POCKET OFFICE',
      TRAINERS__TUTORIALS: 'TUTORIALS',
      TRAINERS__MARKETING_MATERIAL: 'MARKETING MATERIAL',
      TRAINERS__ACTIVATE_PROMOCODE: 'ACTIVATE PROMOCODE',
      RIDERS: 'RIDERS',
      RIDERS__RIDESUM_FOR_RIDERS: 'RIDESUM FOR RIDERS',
      RIDERS__RIDER_PREMIUM: 'RIDER PREMIUM',
      RIDERS__TUTORIALS: 'TUTORIALS',
      RIDERS__ACTIVATE_PROMOCODE: 'ACTIVATE PROMOCODE',
      RIDERS__BOOK_TEST_TRAINING: 'BOOK TEST TRAINING',
      NEWS: 'NEWS',
      SUPPORT: 'SUPPORT',
      SUPPORT__RIDESUM_SUPPORT: 'RIDESUM SUPPORT',
      SUPPORT__CONTACT_US: 'CONTACT US',
      SUPPORT__Q_AND_A: 'Q & A',
      BOOK_AND_CONNECT: 'BOOK TRAINER',
      LOGIN_TRAINER: 'LOGIN TRAINER',
      LANGUAGE: 'LANGUAGE',
      LANGUAGE_EN: 'ENGLISH',
      LANGUAGE_SV: 'SVENSKA',
    },
  },
  CARD_VIDEO: {
    REDIRECT_CONFIRMATION:
      "Video time!\nYou can see the videos in our mobile app. If you don't have it yet, you'll be directed to App Store/Google Play for free download. Enjoy!",
  },
  DIGITAL_COURSES: {
    LEARN_MORE: 'Learn more',
    HOURS: 'hours',
    HOURS_SHORT: 'h',
    LESSONS: 'lessons',
    MINUTES: 'minutes',
    EXERCISES: {
      one: '%{count} exercise booklet',
      other: '%{count} exercise booklets',
    },
    START_COURSE: 'Start course',
    CONTINUE_COURSE: 'Continue course',
    LESSON: 'Lesson',
    LESSON_OF: 'of',
    NEW_COURSE_BANNER_CLICK_HERE: 'Click here!',
    SEE_YOUR_COURSES: 'See your purchased courses here!',
    HEADER: {
      OUR_COURSES: 'Our Courses',
      GIFT_CARD: 'Gift card',
      CONTACT_US: 'Contact Us',
      MY_ACCOUNT: 'My Account',
      REGISTER: 'Register',
      LOGIN: 'Watch your course',
      LOGOUT: 'Logout',
      ADDITIONAL_INFO: 'Sign in to receive extensions',
    },
    FOOTER: {
      MAIN_INFO_TEXT:
        'Ridesum helps you to reach your full potential by gathering experts, training and knowledge in one place.',
      SERVICES: 'Services',
      OUR_COURSES: 'Our Courses',
      ABOUT: 'About',
      CONTACT_US: 'Contact Us',
      FOLLOW_US: 'Follow Us',
      COPYRIGHT: 'Copyright © 2022. Ridesum. All rights reserved.',
    },
    COURSES_LIST: {
      VIEW_ALL_COURSES: 'View all courses',
      VIEW_FEWER_COURSES: 'View fewer courses',
    },
    HOME_SCREEN: {
      NAME: 'Our courses',
      AVAILABLE_COURSES: {
        MAIN_TITLE: 'Learn from the best and reach the next level!',
      },
      ADDITIONAL_INFO: {
        EXCLUSIVE_COURSES_TITLE: 'Exclusive online courses',
        EXCLUSIVE_COURSES_DESCRIPTION: "Learn from some of Sweden's top coaches and experts.",
        PROFESSIONAL_KNOWLEDGE_TITLE: 'The professional knowledge',
        PROFESSIONAL_KNOWLEDGE_DESCRIPTION:
          "During the course's video lessons, you will gain deeper knowledge through theory and practical exercises.",
        TIME_AND_PLACE_TITLE: 'When and where you want',
        TIME_AND_PLACE_DESCRIPTION:
          'Take the course at your own pace, when it suits you and repeat as many times as you want.',
      },
      SPECIAL_COURSES: {
        TOP_TITLE: 'Join Clayton Hicks Courses',
        MAIN_TITLE: 'Clayton Hicks',
      },
      GET_STARTED: {
        TOP_TITLE: 'Choose your course',
        MAIN_TITLE: 'Take the next step in your development',
        MAIN_INFORMATION:
          'Take your riding and horse knowledge to the next level! Learn from the professionals and share their solid knowledge and best tips.',
      },
      POPULAR_COURSES: {
        TOP_TITLE: 'Try the most popular courses',
        MAIN_TITLE: 'Popular Courses',
      },
      LATEST_COURSES: {
        TOP_TITLE: 'We have new items out',
        MAIN_TITLE: 'Latest Courses',
      },
      REVIEWS: {
        TITLE: 'What do the participants say?',
        DESCRIPTION: 'This is what some of our course participants say.',
      },
    },
    SPECIFIC_COURSE_SCREEN: {
      NAME: 'Course',
      BUY_COURSE: 'Buy now',
      BUY_GIFT_CARD: 'Buy gift card',
      ACTIVATE_GIFT_CARD: 'Redeem gift card',
      INSTRUCTOR: 'Trainer',
      ABOUT_INSTRUCTOR: 'About the instructor',
      OVERVIEW: {
        DESCRIPTION_TITLE: 'Overview',
        GOALS_TITLE: 'What you’ll learn',
      },
      PRICE_AND_SUMMARY: {
        PURCHASED_COURSE_TITLE: 'Purchased course',
        INCLUDING_ATTACHMENTS: 'Including Downloadable Exercises',
      },
      EPISODES: {
        TITLE: 'All lessons',
        LESSON_TITLE: 'Lesson',
      },
      REVIEWS: {
        TITLE: 'What do the participants say?',
        DESCRIPTION: 'This is what some of our course participants say.',
      },
      RELATED_COURSES: {
        TOP_TITLE: 'Based on the course you are viewing',
        MAIN_TITLE: 'Related Courses',
      },
    },
    CHECKOUT: {
      NAME: 'Checkout',
      COURSE_TITLE: 'Your course',
      PAYMENT_TITLE: 'Your information',
      PAY_NOW: 'Pay now',
      PROMOCODE: 'Promocode',
      STRIPE_PAYMENT_METHODS: 'Cards, Google Pay and Apple Pay',
      SWISH_DESCRIPTION:
        'Swish will be opened automatically to confirm the payment. If Swish not available on your device, please scan the QR code using Swish.',
      REGISTER: {
        REQUIRED_FIELD: 'Required',
        INVALID_EMAIL: 'Invalid email',
        NOT_CONFIRMED_EMAIL: 'Email is not confirmed',
        INVALID_NAME: 'Invalid name',
        MESSAGE: "We need your email and name first. You'll receive the password from your new account there.",
      },
      ERROR: {
        NO_SUCH_COURSE: "The course doesn't exist. Make sure the URL is correct.",
        ALREADY_BOUGHT: 'You already own the course.',
        PROCESSING_PAYMENT: "We're still processing your payment.",
        UNKNOWN_ERROR: 'An unexpected error occurred.',
        INVALID_PROMOCODE: 'Invalid promocode',
        SWISH_PAYMENT_ALREADY_IN_PROGRESS:
          'A payment is already in progress. Please confirm or cancel previous payment attempt in Swish',
        SWISH_TRANSACTION_DECLINED: 'Transaction declined',
        SWISH_BANKID_CANCELED: 'BankId signing was canceled',
        SWISH_BANK_ERROR: 'Bank system processing error',
        PAYMENT_DECLINED: 'Payment declined by user',
        SWISH_PAYMENT_REQUEST_EXPIRED: 'The payment request has expired, please try again',
        INVALID_GIFT_CARD_CODE: 'Entered gift card code is invalid. Please double-check the spelling and try again',
        GIFT_CARD_EXPIRED: 'The gift card has expired. The gift card can no longer be used',
        GIFT_CARD_ALREADY_ACTIVATED: 'The gift card has already been activated',
      },
      PURCHASE_RESULT: {
        SUCCESS: {
          TITLE: 'Success!',
          BODY: 'Thank you for you purchase, information has been sent to your email',
          NOTE_TITLE: 'Note: ',
          NOTE_BODY:
            'If you already have a Ridesum account, log in with it to see the course. If you do not have a Ridesum account before, please see your e-mail for login information.',
        },
        PROCESSING: {
          TITLE: 'Processing...',
          BODY: "We're still processing your purchase.\nWhen it's ready we'll send information to your email",
        },
        FAILURE: {
          TITLE: 'Ups!',
          BODY: "Sorry, the payment didn't go through.\nPlease try again.",
        },
        WAITING_SWISH: {
          TITLE: 'Confirm payment in Swish',
          SCAN_QR: 'Please scan the QR code using Swish',
        },
      },
    },
    WATCH: {
      LESSONS_LIST: 'Course plan',
      BACK_TO_COURSE_PREVIEW: 'Back to course preview',
      LESSON_NUMBER: 'Lesson %{number}',
      TEXT_INFORMATION: 'About the lesson',
      DOWNLOAD_MATERIALS: 'Download materials',
      COMPLETED_LESSON: 'Completed',
      NOT_COMPLETED_LESSON: 'Not completed',
      PREVIOUS_LESSON: 'Previous lesson',
      NEXT_LESSON: 'Next lesson',
    },
    PROFILE_SCREEN: {
      NAME: 'My account',
      GREETINGS: 'Hello',
      EXPLANATORY_TEXT: 'Here are all the courses that you’ve watched.',
      COMPLETED_COURSES: 'Completed courses',
      ACTIVE_COURSES: 'Active courses',
      TRAINING_HOURS: 'Hours of training',
      ALL: 'All',
      ACTIVE: 'Active',
      COMPLETED: 'Completed',
      NOT_STARTED: 'Not started',
      PURCHASED_COURSES: {
        TOP_TITLE: 'Here we have collected all your courses',
        MAIN_TITLE: 'Courses you are taking',
      },
    },
    GIFT_CARD_OVERVIEW: {
      TITLE: "Take a horse riding course with Sweden's top trainers!",
      GIFT_CARD: 'Gift card',
      PAGE_DESCRIPTION: `Buying a gift card for a horse-loving friend - an appreciated gift! Have you received a gift card? Redeem it and start any course of your choice today.

In Ridesum's digital courses, Sweden's most skilled trainers and experts share their best tips and exercises.

A course is about 2 hours and contains 6 unique video lessons as well as downloadable exercises.

The recipient can take the course as many times as they want during a year, at any time and at their own pace.`,
      INSTRUCTIONS_HEADER: 'How does it work?',
      INSTRUCTIONS: {
        TITLE_1: '1. Buy a gift card',
        TEXT_1: 'You get a nice gift card to print or forward to the recipient.',
        TITLE_2: '2. Give away',
        TEXT_2: 'Applies to any course for one year.',
        TITLE_3: '3. Activate and select course',
        TEXT_3: "Your friend redeems the gift card and chooses a course. Then it's just a matter of starting!",
      },
      WHAT_OTHERS_THINK: 'What do our course participants think?',
      QUOTE_TEXT: '”I am completely overwhelmed! Educational and inspiring. Great courses!”',
      QUOTE_AUTHOR: '- Gun, Stockholm',
      WHATS_RATING: 'What is your overall impression of the course?',
      POPULAR_COURSES: 'Popular courses',
      GIFT_TO_HORSE_LOVER: 'Make a horse lover happy - gift a course!',
    },
    GIFT_CARD_CHECKOUT: {
      BUY_PART_1: 'Buy a gift card for',
      BUY_PART_2: 'Receive the gift card and activation code on your e-mail immediately.',
      ACTIVATE_CODE: 'Activate gift card code',
      ACTIVATION_INSTRUCTIONS:
        'Your friend activates* the gift card with the code and chooses a course. Then it’s just to start!',
      REMARK: '*The gift card is valid 1 year from the date of purchase.',
      GIFT_CARD_WITH_PRICE: '%{price} gift card',
      GIFT_CARD_INSTRUCTIONS:
        'The gift card will be sent to your e-mail. Your friend uses the code to watch any Ridesum course for free*',
      MATERIALS: 'Materials',
      POSSIBLE_COURSE: 'Possisble course',
      POSSIBLE_COURSE_WITH_TRAINER: "Possible course with one of Sweden's best trainers & experts!",
    },
    GIFT_CARD_REDEEM: {
      REDEEM_COURSE: 'Activate course',
      ENTER_CODE: 'Enter the gift card code here',
      INSTRUCTIONS:
        'After you activated the gift card, you can watch the course <b>%{courseTitle}</b> via your Ridesum rider account. Instructions will be sent to your e-mail.',
      SELECT_COURSE: 'Select a different course to activate',
      CODE_IS_VALID: 'The code is valid',
    },
    GIFT_CARD_REDEEMED: {
      ACTIVATED: 'Gift card activated',
      INSTRUCTIONS: 'You successfully activated the gift card! The instructions were sent to your e-mail.',
    },
    RESET_PASSWORD: {
      CREATE_PASSWORD: 'Please create password for your Ridesum Rider account',
      CONFIRM_PASSWORD: 'Confirm password',
      PASSWORDS_DONT_MATCH: "Passwords don't match",
      LINK_EXPIRED:
        'Unfortunately, this link is no longer valid. You can order a new link which will be sent on your email',
      LINK_RESENT: `The new link has been sent. Please check your inbox.
You can close this tab now`,
      SEND_NEW_LINK: 'Send new link',
    },
  },
  COMMON_FOOTER: {
    QUICK_LINKS: 'QUICK LINKS',
    TRAINER: 'Trainer',
    RIDER: 'Rider',
    DIGITAL_COURSES: 'Digital courses',
    SEARCH_TRAINER: 'Search trainer',
    NEWS: 'News',
    INSTRUCTIONAL_VIDEOS: 'Instructional videos',
    CONTACT: 'Contact',
    SUPPORT_WITH_RIDESUM: 'Support with Ridesum',

    ABOUT_RIDESUM: 'About Ridesum',
    JOB: 'Job',
    TEAM: 'Team',
    STORY: 'Story',
    MISSION: 'Mission',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_OF_USE: 'Terms of use',
    COPYRIGHT: '© 2022 Ridesum',

    SUBSCRIBE_LABEL: 'Subscribe',
    INDICATES_REQUIRED_LABEL: 'indicates required',
    EMAIL_LABEL: 'Email',
    LANGUAGE_LABEL: 'Language',
    RIDER_OR_TRAINER_LABEL: 'Are you rider or trainer/coach?',
    CAPTCHA_LABEL: 'captcha',

    SUBSCRIPTION_ERRORS: {
      REQUIRED_FIELD: 'This field is required.',
      INVALID_EMAIL: 'Please enter a valid email address.',
    },
  },
  RIDER_STREAM: {
    NO_MINUTES_LEFT: 'You’re out of streaming minutes',
    MINUTES_EXPIRED:
      'There is a risk that your streaming minutes will run out during the training. Upgrade your subscription via your Profile in the menu. Happy training!',
    MINUTES_EXPIRED_DURING_SESSION:
      'You can finish training today, but keep in mind that you need to upgrade your subscription before the next session to get more streaming minutes. You do this under Profile.',
    UPGRADE_TO_PREMIUM: 'Upgrade to Premium',
    UPGRADE_TO_PREMIUM_DESCRIPTION: 'Recording video is available for Premium account.',
    READ_MORE: 'Read more',
    TRAINING_IS_PAUSED: 'The session is paused',
    CONTINUE_TRAINING: 'Continue session',
    FINISH_TRAINING: 'End session',
    HAVING_PROBLEMS_WITH_STREAMING: 'Having problems with streaming?',
    OPEN_IN_CHROME: 'Open in Chrome',
    TRAINING_IS_FINISHED: 'The training is finished',
    MAY_CLOSE_WINDOW_NOW: 'Now you can close this window and return to Ridesum',
    RETURN_TO_RIDESUM: 'Return to Ridesum',
  },
  ANALYSES: {
    TRY_AISA_NOW: 'Try AI Seat Analytics now',
    TRY_AIHA_NOW: 'Try AI Horse Analytics now',
  },
  GPS_TRACKER: {
    RIDING_HORSE: 'riding %{horseName}',
    OFFLINE: 'offline',
    SPEED_KPH: '%{speedKph} kph',
    NO_UPDATES: {
      TITLE: 'No location updates',
      MESSAGE:
        'There were no location updates in a while. The Internet connection might be temporarily unavailable or the app has been closed.',
    },
    FINISHED: {
      TITLE: 'Tracking is finished',
      MESSAGE: 'You can close this page now.',
    },
  },
  ERROR_BOUNDARY: {
    APP_CRASHED: 'The app crashed',
    WE_WILL_PROCESS_PROBLEM: 'We will process the problem.',
    YOU_CAN_TRY_AGAIN: 'You can try refreshing the page.',
  },
  WEB_MEETING: {
    CREATE_LINK: 'Create meeting link',
    CREATE: {
      TITLE: 'New meeting',
      DATE: 'When',
      EMAIL: 'Customer email',
      NAME: 'Customer name',
      PHONE: 'Customer phone number',
      ADDRESS: 'Customer address',
      CREATE: 'Create',
    },
    RESULT: {
      TITLE: 'The link is ready',
      COPY: 'Copy to clipboard',
      COPIED: 'Copied!',
      BACK: 'Back to home',
    },
    PRE_CALL: {
      WILL_BE_RECORDED: 'The entire meeting will be recorded',
    },
    FINISHING: {
      TITLE: 'Leaving the meeting',
      MESSAGE: 'Do you want to leave the meeting now?',
    },
    FINISHED: {
      TITLE: 'The meeting is finished',
      MESSAGE: 'You can close the page now',
    },
  },
};
