import axios from 'axios';
import i18n from './i18n';
import Settings from './constants';
import Storage from './storage';
import getTimeZoneName from './utilities/getTimeZoneName';

const { apiUrl } = Settings;

let refreshRequestPromise = null;
axios.interceptors.request.use(request => {
  if (!request.isAnonymous && Storage.authToken) {
    request.headers.Authorization = `Bearer ${Storage.authToken}`;
  }
  request.headers['Accept-Language'] = i18n.locale;
  request.headers['X-Client-Platform'] = 'Web';
  request.headers['X-Time-Zone'] = getTimeZoneName();
  return request;
});
axios.interceptors.response.use(
  respose => respose,
  async error => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest.retryAfterRefresh) {
      originalRequest.retryAfterRefresh = true;
      try {
        // Prevents concurrent refresh token API calls
        if (!refreshRequestPromise) {
          refreshRequestPromise = refreshAuthToken(Storage.refreshToken)
            .catch(() => {
              Storage.authToken = null;
              Storage.refreshToken = null;
            })
            .finally(() => {
              refreshRequestPromise = null;
            });
        }
        const refreshResult = await refreshRequestPromise;
        Storage.authToken = refreshResult.access_token;
        Storage.refreshToken = refreshResult.refresh_token;

        return axios(originalRequest);
      } catch (e) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  },
);

const get = async (relative, params, config) =>
  (
    await axios.get(apiUrl + relative, {
      params,
      ...config,
    })
  ).data;

const post = async (relative, data, config) => (await axios.post(apiUrl + relative, data, config)).data;

const getUnauthorized = async (relative, params, config) =>
  (
    await axios.get(apiUrl + relative, {
      isAnonymous: true,
      params,
      ...config,
    })
  ).data;

const put = async (relative, data) =>
  (
    await axios.put(apiUrl + relative, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).data;

const patch = async (relative, data) =>
  (
    await axios.patch(apiUrl + relative, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).data;

const postUnauthorized = async (relative, data, config) =>
  (
    await axios.post(apiUrl + relative, data, {
      isAnonymous: true,
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true, // sends cookies
      ...config,
    })
  ).data;

const deleteMethod = async relative =>
  (
    await axios.delete(apiUrl + relative, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
  ).data;

export const login = (userName, password) =>
  axios.post(
    `${apiUrl}/token`,
    `grant_type=password&client_id=rds.client&username=${encodeURIComponent(userName)}&password=${encodeURIComponent(
      password,
    )}`,
    { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } },
  );

export const refreshAuthToken = refreshToken =>
  postUnauthorized(`/token`, `grant_type=refresh_token&client_id=rds.client&refresh_token=${refreshToken}`, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

export const postValidatePasswordResetToken = token => postUnauthorized('/users/password-reset/validate', { token });
export const postResetPassword = ({ token, newPassword }) =>
  postUnauthorized('/users/password-reset', { token, newPassword });
export const postResendPasswordResetEmail = token => postUnauthorized('/users/password-reset/resend-email', { token });
export const getProfileData = () => get('/users/me');

export const getActivity = (type, id) => get(`/activities/${type}/${id}`, { loadInvoiceInformation: true });

export const getActivities = skip => get('/activities', { count: 15, skip, loadInvoiceInformation: true });

export const getActivityRequests = (count, skip) => get('/activities/requests', { count, skip });

export const createOpenTokSession = () => post('/training-sessions');

export const getOpenTokToken = sessionId => get(`/training-sessions/${sessionId}/token`);
export const getRiderOpenTokToken = sessionId => get(`/training-sessions/stream/${sessionId}/getParameters`);
export const postStartArchive = ({ sessionId, layout, name }) =>
  post(`/training-sessions/${sessionId}/archive`, { layout, name });
export const postStopArchive = archiveId => post(`/training-sessions/archive/${archiveId}/stop`);
export const postStreamingSeconds = (sessionId, streamingSecondsSpent) =>
  post(`/training-sessions/stream/${sessionId}/update?secondsSpent=${streamingSecondsSpent}`);
export const postDunstanCreateWebMeeting = data => post('/training-sessions/dunstan/web-meetings', data);
export const getDunstanWebMeetingInfo = urlKey => get(`/training-sessions/dunstan/web-meetings/${urlKey}`);
export const getDunstanWebMeetingPreCall = urlKey => get(`/training-sessions/dunstan/web-meetings/${urlKey}/pre-call`);
export const getDunstanVideoUrl = video => get('/training-sessions/archive/dunstan', { video });

export const postSendFeedback = (type, id, feedback) => post(`/activities/${type}/${id}/sendFeedback`, { feedback });

export const postSaveFeedback = (type, id, feedback) => post(`/activities/${type}/${id}/feedback`, { feedback });

export const listUsers = (type, newsLetter, order, skip, count) =>
  get('/users/list', { type, newsLetter, order, skip, count });

export const getTrainerActivity = id => get(`/activities/trainer/${id}`);
export const resetUserCache = () => post('/users/reset-cache');
export const getCoursesPayments = ({ userId, statuses, continuationToken, limit }) => {
  const query = new URLSearchParams();
  if (userId) {
    query.append('userId', userId);
  }
  if (continuationToken) {
    query.append('continuationToken', continuationToken);
  }
  if (limit) {
    query.append('limit', limit);
  }
  statuses.forEach(s => query.append('statuses', s));
  return get(`/digital-courses/payments?${query}`);
};
export const getCoursePaymentDetails = id => get(`/digital-courses/payments/${id}`);
export const getCoursesNotOwnedByUser = userId => get('/digital-courses/not-owned', { userId });
export const postGiveCourseToUser = (userId, courseId) => post('/digital-courses/give', { userId, courseId });

// ----------- User Profile Page ------------
export const getTrainerStudents = trainerId => get(`/students/${trainerId}`);
export const getTrainerRequests = trainerId => get(`/activities/requests/${trainerId}`);
export const getTrainerUpcoming = trainerId =>
  get(`/activities/upcoming/trainer/${trainerId}`, { loadInvoiceInformation: true });
export const getRiderUpcoming = riderId => get(`/activities/upcoming/rider/${riderId}`);
export const cancelActivity = (id, cancelMessage) =>
  post('/activities/bulk/cancel', { activitiesIds: [id], cancelMessage });
export const declineActivity = (id, declineMessage) =>
  post('/activities/bulk/decline', { activitiesIds: [id], declineMessage });
export const acceptActivity = (type, id, acceptMessage) => post(`/activities/${type}/${id}/accept`, { acceptMessage });
export const getAdminSearchProfile = (searchField, searchTerm) => get(`/users/search`, { searchField, searchTerm });
export const getUser = id => get(`/users/role/${id}`);
export const getUserEntitlements = id => get(`/users/${id}/entitlements`);
export const deleteUser = (role, id) => deleteMethod(`/users/${role}/${id}`);
export const editUserInformation = (role, id, data) => put(`/users/${role}/${id}`, data);
export const postApplyPromocode = (email, promocodeId, role) =>
  postUnauthorized('/purchase/apply-promocode', undefined, { params: { email, promocodeId, role } });
export const postGrantPremiumStatus = data => post('/purchase/grant-premium', data);
export const postRevokePremiumStatus = (userId, promocodeId) =>
  post('/purchase/revoke-premium', { userId, promocodeId });
export const postInvoice = invoice => post('/paymentInvoiceV2', invoice);
export const getCompetences = () => getUnauthorized('/competences');
export const getRiderTrainers = riderId => get(`/trainers/${riderId}`);
export const getCountries = () => get('/countries');
export const getDistricts = () => get('/districts');
export const getAdminComments = id => get(`/comments/${id}`);
export const postAdminComments = commentObj => post('/comments', commentObj);
export const ratingSession = rating => post('/activities/ratingSession', rating);

export const getAssignedPromocodes = userId => get(`/purchase/assigned-promocodes`, { userId });
export const getAvailablePromocodes = userId => get(`/purchase/available-promocodes`, { userId });

export const logStreamingQualityMetrics = metrics => post('/training-sessions/stream/logStreamQualityMetrics', metrics);
export const getNewlyAddedTrainerReviews = trainerId =>
  get(`/users/reviews/v2?trainerId=${trainerId}&status=NewlyAdded`);
export const getApprovedTrainerReviews = trainerId =>
  getUnauthorized(`/users/reviews/v2?trainerId=${trainerId}&status=Approved`);
export const getTrainerReview = reviewId => get(`/users/reviews/${reviewId}`);
export const approveTrainerReview = reviewId => patch(`/users/reviews/${reviewId}/approve`);
export const rejectTrainerReview = reviewId => patch(`/users/reviews/${reviewId}/reject`);
export const getFilteredTrainerReviewsForAdmin = status => get(`/users/reviews/searched?status=${status}`);
export const getTrainerMaterials = trainerId => getUnauthorized(`/users/trainer/${trainerId}/materials`);

// ----------- Book and Connect (/trainers) ------------
export const getSearchParameters = () => getUnauthorized('/book-and-connect/search-parameters');
export const getTrainersOverview = () => getUnauthorized('/book-and-connect/trainers-overview');
export const getHighlightedTrainers = ({ skip = 0, take = 6 }) =>
  getUnauthorized(`/book-and-connect/trainers-highlighted?skip=${skip}&take=${take}`);
export const getStandardTrainers = ({ skip = 0, take = 6 }) =>
  getUnauthorized(`/book-and-connect/trainers-standard?skip=${skip}&take=${take}`);
export const postSearchTrainers = parameters => postUnauthorized('/users/trainers/search', parameters);
export const getProfileTrainer = id => getUnauthorized(`/book-and-connect/trainer/${id}`);
export const getCategoryInfo = id => getUnauthorized(`/book-and-connect/disciplines/${id}`);
export const getSimilarTrainers = id => getUnauthorized(`/book-and-connect/trainer/${id}/similar`);

export const postTrainerProfileViewed = (trainerId, context) =>
  postUnauthorized('/book-and-connect/log/trainer-profile-viewed', { trainerId, context });
export const postTrainerBookPressed = trainerId =>
  postUnauthorized('/book-and-connect/log/trainer-book-pressed', { trainerId });
export const postInterestViewed = interestId =>
  postUnauthorized('/book-and-connect/log/interest-viewed', { interestId });

// ----------- Digital courses (/digitalakurser) ------------
export const getCoursesHomeInformation = ({ promocode }) => get('/digital-courses/home', { promocode });
export const getAvailableCourses = ({ signal, promocode }) =>
  get('/digital-courses/available', { promocode }, { signal });
export const getCoursesSpecificCourseInformation = (courseId, { promocode }) =>
  get(`/digital-courses/${courseId}`, { promocode });
export const postAbandonedCartFollowed = cartId => postUnauthorized(`/digital-courses/abandon-cart/${cartId}/follow`);
export const getCoursesCheckoutInformation = (courseId, { email = null, currency = null, promocode = null }) =>
  getUnauthorized(`/digital-courses/checkout/${courseId}`, { email, currency, promocode });
export const getCoursesCheckoutWithDiscountInformation = (courseId, promocode) =>
  getUnauthorized(`/digital-courses/checkout/${courseId}/${encodeURIComponent(promocode)}`);
export const getStripePublishableKey = () => getUnauthorized('/payment/publishableKey');
export const postBuyCourseWithStripe = ({ courseId, email, name }) =>
  postUnauthorized('/digital-courses/purchase', { courseId, email, name });
export const postCourseCheckoutPromocode = ({ courseId, email, promocode }) =>
  postUnauthorized('/digital-courses/apply-purchase-promocode', { courseId, email, promocode });
export const postBuyCourseWithSwish = (courseId, { email, timeZoneName }) =>
  postUnauthorized(`/digital-courses/checkout/${courseId}/swish`, { email, timeZoneName });
export const getLongPollSwishPaymentResult = (paymentId, signal) =>
  getUnauthorized(`/digital-courses/checkout/swish/${paymentId}/long-poll`, null, { signal });
export const getSwishQrCodeImageUrl = paymentId => `${apiUrl}/digital-courses/checkout/swish/${paymentId}/qr.png`;
export const getGiftCardCheckoutInformation = ({ currency = null }) =>
  getUnauthorized('/digital-courses/gift-cards/checkout', { currency });
export const postBuyGiftCardWithStripe = ({ email, name, timeZoneName }) =>
  postUnauthorized('/digital-courses/gift-cards/checkout/stripe', { email, name, timeZoneName });
export const postBuyGiftCardWithSwish = ({ email, timeZoneName }) =>
  postUnauthorized('/digital-courses/gift-cards/checkout/swish', { email, timeZoneName });
export const getGiftCardRedeemInfo = () => getUnauthorized('/digital-courses/gift-cards/redeem');
export const postVerifyGiftCardCode = redeemCode =>
  postUnauthorized('/digital-courses/gift-cards/redeem/verify', { redeemCode });
export const postRedeemGiftCard = ({ email, name, redeemCode, courseId }) =>
  postUnauthorized('/digital-courses/gift-cards/redeem', { email, name, redeemCode, courseId });
export const postGenerateGiftCard = ({ count, priceAmount, priceCurrency }) =>
  post('/digital-courses/gift-cards/generate', { count, priceAmount, priceCurrency }, { responseType: 'blob' });

export const getPreviousLessonToWatch = courseId => get(`/digital-courses/watch/${courseId}`);
export const getSpecificLessonToWatch = (courseId, lessonNumber) =>
  get(`/digital-courses/watch/${courseId}/${lessonNumber}`);
export const postLessonWatchTime = (courseId, lessonNumber, timeMillis) =>
  post(`/digital-courses/watch/${courseId}/${lessonNumber}/watch-time?time=${timeMillis}`);

export const getCoursesProfileInformation = () => get('/digital-courses/profile');

// ----------- Client logging ------------
export const getLogLevels = () => get('/client-log/log-levels');
export const postLogEvents = events => post('/client-log', { events });

// ----------- Misc ------------
export const getAnalysisShareInfo = shareId => getUnauthorized(`/equi-tools/analysis/share/${shareId}/social`);
export const getGpsTrackerConnectionInfo = shareToken =>
  getUnauthorized('/trainingDiary/event/share/ride/connect', { shareToken });
export const getGpsTrackerDetails = shareToken =>
  getUnauthorized('/trainingDiary/event/share/ride/details', { shareToken });
export const getHorseEventRidingRoute = shareToken =>
  getUnauthorized(`/trainingDiary/event/share/ride/route`, { shareToken });

// ----------- Delete this ------------
export const postBuyBlackdogAnalyses = () => postUnauthorized('/equi-tools/sihs/buy');
export const getBlackdogAnalysesPaymentResult = paymentId => getUnauthorized(`/equi-tools/sihs/result/${paymentId}`);
