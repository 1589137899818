// eslint-disable-next-line import/no-anonymous-default-export
export default {
  date: {
    month_names: [
      null,
      'Januari',
      'Februari',
      'Mars',
      'April',
      'Maj',
      'Juni',
      'Juli',
      'Augusti',
      'September',
      'Oktober',
      'November',
      'December',
    ],
    abbr_month_names: [null, 'Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec'],
    day_names: ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'],
    abbr_day_names: ['Sön', 'Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör'],
  },
  ONLINE_TRAINING: 'Online-pass',
  PLEASE_SUMMARIZE:
    'Inspelning kan ske. Vänligen observera att du har ansvar för att informera personer som förekommer i bakgrunden att inspelning kan ske när du använder din webbkamera. Ytterligare information om Ridesums behandling av personuppgifter finns på vår webbsida.',
  WRITE_FEEDBACK: 'Skriv feedback',
  FEEDBACK_TEMPLATE: `•  Vad var bra?
•  Vad kan utvecklas och hur?
•  Tips på övningar?
•  Övrig pepp!`,
  RIDER: 'Ryttare',
  MESSAGE: 'Meddelande',
  TRAINING_IS_PAUSED: 'Passet är pausat',
  CONTINUE_TRAINING: 'Fortsätt passet',
  FINISH_TRAINING: 'Avsluta passet',
  FEEDBACK_NOTES: 'Feedback noteringar',
  SESSION_EXPIRED: 'Sessionen löpte',
  YOUR_FEEDBACK: 'Din feedback',
  CREATE_AUDIO_FEEDBACK_IN_APP: 'Du vet att du enklare kan skriva och skicka feedback direkt i Ridesum appen.',
  WRITE_FEEDBACK_TO_RIDER_HERE: 'Skriv din feedback till ryttaren här.',
  SEND: 'Skicka',
  BACK: 'Tillbaka',
  FEEDBACK: 'Film',
  TRAINING: 'Pass',
  LINK_TO_VIDEO: 'Länk till film',
  DEADLINE: 'Deadline',
  TIME: 'Tid',
  DURATION: 'Längd',
  START_TRAINING: 'Starta pass',
  YOU_CAN_SAVE_AND_SEND_LATER: 'Du kan spara samt redigera och skicka senare om du vill.',
  SAVE: 'Spara',
  EMAIL: 'E-post',
  EMAIL_REQUIRED: 'E-postadress obligatoriskt',
  CONFIRM_EMAIL: 'Bekräfta e-postadress',
  PASSWORD: 'Lösenord',
  PASSWORD_REQUIRED: 'Lösenord obligatoriskt',
  REMEMBER_ME: 'Kom ihåg mig',
  FORGOT_PASSWORD_IN_MOBILE_APP: 'Glömt lösenord? Gå till mobilappens inloggning och "Glömt lösenord".',
  ERROR: 'Fel',
  LOGIN_ERROR: 'Fel e-postadress eller lösenord',
  SERVER_ERROR: 'Serverfel',
  UNEXPECTED_ERROR: 'Ett oväntat fel inträffade.',
  OK: 'Ok',
  TRY_AGAIN: 'Testa igen',
  YOUR_MESSAGE_SENT: 'Tack! Ditt meddelande har skickats!',
  RIDER_NOTIFIED: 'Ryttaren meddelas.',
  YOUR_FEEDBACK_SENT: 'Toppen! Din feedback skickas.',
  LOGOUT: 'Logga ut',
  SEE_ALL: 'Se allt',
  SEE_LESS: 'Se mindre',
  RIDER_MESSAGE: 'Ryttarens meddelande',
  CUSTOMER_MESSAGE: 'Kundens meddelande',
  TRAINER_MESSAGE: 'Tränarens meddelande',
  MY_MESSAGE: 'Mitt meddelande',
  IMPORTANT: 'Viktigt',
  YOU_MUST_ACCEPT_REQUEST_IN_MOBILE:
    'Du måste acceptera bokningsförfrågan i mobilappen för att Pass/Film ska visas här.',
  BROWSER_CHROME: 'Google Chrome',
  BROWSER_SAFARI: 'Safari',
  WARNING_MESSAGE: 'För en bättre upplevelse med Ridesum rekommenderar vi starkt att du använder',
  CONTINUE: 'Fortsätt',
  LOGIN: 'Logga in',
  LOGIN_SHORT_SLOGAN: 'Dags att fatta galoppen, gå med i Ridesum idag!',
  LOGIN_LONG_SLOGAN:
    'Med Ridesum får du som tränare och hästföretagare får du mer synlighet och tillgång till funktioner som hjälper dig att arbeta smartare. Du kan coacha digitalt och hålla tätare kontakt med dina kunder. Via vårt boknings- och faktureringssystem får du mer tid till det du älskar. Arbeta smartare, spara tid och skala upp din verksamhet!',
  GET_STARTED_TODAY_LABEL: 'Kom igång idag!',
  TRAINING_TYPE: {
    traditional_training: 'Face-to-face',
    training: 'Digital',
    feedback: 'Film',
  },
  RIDESUM_SLOGAN: 'Träna när du vill, var du vill',
  REGISTRATION_SUGGESTION:
    'Saknar du konto? Vänligen skapa ett i mobilappen som du laddar ner på AppStore eller Google Play.',
  UPCOMING: 'Kommande',
  CALENDAR: 'Kalender',
  NO_ACTIVITIES:
    'För närvarande har du inga väntande eller godkända bokningsförfrågningar. När du har fått en kommer den att visas på den här sidan.',
  PENDING_REQUESTS: 'Pending booking requests',
  RESPONSE_DATE: 'Datum för återkoppling',
  LOCATION: 'Plats',
  SESSION_ID: 'Session ID',
  COPY_SESSION_ID: 'Kopiera Session ID',
  NO_ACTIVITIES_REGISTERED: 'Inga aktiviteter registrerade',
  REQUEST_DATE: 'Datum för förfrågan',
  REDEEM_CODE: 'Aktivera kod',
  VERIFY_CODE: 'Aktivera kod',
  PROMOCODE_INSTRUCTIONS: `1. Ha en registrerad profil i appen - ange e-postadress
  2. Ange koden du fått, välj om du är ryttare eller tränare
  3. Nu finns erbjudandet registrerat på ditt Ridesumkonto`,
  PROMOCODE_SERVER_ERROR: {
    invalid_promocode: 'Koden är inte aktiv',
    user_not_exist: 'Emailadressen är inte registrerad (Registrera dig i appen först)',
  },
  PROMOCODE_APPLIED: 'Koden aktiverad för ditt konto',
  PROMOCODE_REQUIRED: 'Kod obligatorisk',
  PREMIUM_SETTINGS: 'Premiuminställningar',
  PROMOCODE: 'Promokod',
  APPLY_PROMOCODE: 'Använd',
  DISCOUNT: 'Rabatt',
  BOOKINGS: 'Aktiviteter',
  CONNECTED_RIDERS: 'Kontakter',
  NAME: 'Namn',
  AGE: 'Ålder',
  STUDENT_SINCE: 'Kund från',
  PHONE: 'Telefon',
  PHONENR: 'Telefon nr',
  YEAR: 'år',
  CONNECTED_RIDERS_TO: 'Kunder till',
  CONNECTED_TRAINERS_TO: 'Tränare till',
  REQUESTS: 'Förfrågningar',
  RIDERS_MESSAGE: 'Ryttarens meddelande',
  TRAINERS_MESSAGE: 'Tränarens meddelande',
  START: 'Start',
  FAILED: 'Avbruten',
  DONE: 'Betald',
  WAITING: 'Väntande',
  TODAY: 'Idag',
  PENDING: 'Väntar svar',
  ACTIVITIES_OF: 'Aktiviteter för',
  ACCEPTED: 'Accepterad',
  CANCEL_OR_NEW_TIME: 'Avboka/Ny tid',
  SUGGEST_SEVERAL_DATES: 'Vänligen föreslå flera nya datum/tider om efterfrågad tid inte passar dig',
  ACCEPT: 'Acceptera ',
  ACCEPT_REQUEST: 'Acceptera förfrågan',
  ACCEPT_MESSAGE_PROMPT: 'Här kan du skriva meddelande till ryttaren, t.ex. bekräfta priset.',
  WRITE_MESSAGE: 'Skriv ett meddelande…',
  FEEDBACK_ON_VIDEO: 'Feedback på film',
  RIDESUM_TRAINING: 'Ridesum-träning',
  SHARE_FEEDBACK_WITH_PAYMENTS: 'Dela feedback med betalning',
  CANCEL: 'Avbryt',
  INVOICE_SENT_TITLE: 'Faktura skickad',
  INVOICE_SENT_SUCCESS_1:
    'Din faktura är skickad till ryttaren. Ryttaren får information om detta & kan betala med ett klick. ',
  INVOICE_SENT_SUCCESS_2: 'Du hittar fakturan under Premium i mobilappen.',
  TRIGGER_PAYMENT: 'Aktivera betalning',
  TRIGGER_PAYMENT_ERROR:
    'Det ser ut som att det saknas någon information för att skicka fakturor. Öppna appen och gå till betalningar.',
  INCLUDE_VAT: 'inkl moms',
  TRAINER: 'Tränare',
  PREMIUM: 'Premium',
  FREE: 'FREE',
  BASIC: 'BASIC',
  PRO: 'PRO',
  CLOSE: 'Stäng',
  SESSIONS_HISTORY: 'Träningshistorik',
  STATUS: 'Status',
  NEWLY_ADDED_TRAINER_REVIEW_STATUS: 'Nyligen tillagd',
  APPROVED_TRAINER_REVIEW_STATUS: 'Godkänd',
  REJECTED_TRAINER_REVIEW_STATUS: 'Avvisad',
  TRAINER_NAME: 'Tränarens namn: {trainerName}',
  PUBLIC_UPLOADS: 'Uppladdningar',
  TRAINER_REVIEWS: 'Recensioner ({numberOfReviews})',
  ALL_TRAINER_REVIEWS: 'Alla tränarrecensioner',
  SEARCH_TRAINER_REVIEW: 'Sök tränare recension',
  NEW_TRAINER_REVIEWS: 'Nya recensioner',
  NO_SEARCH_REVIEWS_FOUND: 'Ingen rekommendation har hittats för denna begäran.',
  NO_TRAINER_REVIEWS_ADDED: 'Inga recensioner har lämnats för denna tränare ännu.',
  AVERAGE_RATING: 'Snittbetyg',
  TRAINER_REVIEW_DETAILS: 'Recension',
  TRAINER_REVIEW_DETAILS_TITLE: 'Rekommendation om tränare {trainerName} har lämnats av ryttare {authorName}.',
  TRAINER_REVIEW_FIRST_QUESTION: 'Skulle du rekomendera {trainerName} till andra Ridesum användare?',
  TRAINER_REVIEW_SECOND_QUESTION: 'Hur engagerad är {trainerName} i din utveckling?',
  TRAINER_REVIEW_THIRD_QUESTION: 'Hur fokuserad var {trainerName} under träningen?',
  TRAINER_REVIEW_FOURTH_QUESTION: 'Känner du att {trainerName} hjälper dig att utvecklas?',
  TRAINER_TEXT_FEEDBACK_LABEL: 'Fritext',
  RIDER_LEFT_ANONYMOUS_REVIEW: 'Användaren föredrog att lämna en anonym recension',
  APPROVE: 'Godkänna',
  REJECT: 'Avvisa',
  DELETE_USER: 'Ta bort användare',
  SEARCH_USER: 'Sök användare',
  SEARCH_BY: 'Sök efter',
  SEARCH: 'Sök',
  SEARCHFOR: 'Vem / Vad söker du?',
  COUNRTY: 'Land',
  CITY: 'Stad',
  DISTRICT: 'Ort',
  COMPETENCES: 'Kompetens',
  EDIT: 'Redigera',
  DECLINED: 'Avböjt',
  ADD: 'Lägg till',
  ACCOUNT_STATUS: 'Konto status',
  LAST_LOGIN_TIME: 'Senaste inloggningen',
  WEBSITE: 'Hemsida',
  COUNTRY: 'Land',
  ADD_COMPETENCE: 'Lägg till kompetens',
  SUM: 'Sammanfattning',
  PRICES: 'Priser',
  PRICE: 'Pris',
  PRICE_45_MIN: '45 min digitalt pass',
  ENTER_PRICE: 'Skriv in pris',
  ENTER_PRICE_TITLE: 'Längd & träningsform',
  SAVE_CHANGES: 'Spara ändringar',
  NO_USER_FOUND: 'Gnägg! Här hittades ingen! Försök igen.',
  COPY_USER_ID: 'Kopiera ID-nummer',
  PLUS: 'PLUS',
  CANCELLED: 'Avbokad',
  TRAINERS: 'Tränare',
  RIDERS: 'Ryttare',
  ID: 'Id',
  REGISTRATION_DATE: 'Registrerings datum',
  ADDRESS: 'Adress',
  COPY_SESSION_ID_SUCCESS_TITLE: 'Session ID är kopierat!',
  COPY_SESSION_ID_SUCCESS_TEXT: 'Grattis! Du har precis lyckats kopiera Sessions ID till din dator.',
  ZIP_CODE: 'Postnummer',
  EDIT_USER: 'Redigera användare',
  ADMIN_COMMENT_REPORTER: 'Rapportör',
  ADMIN_COMMENT_MESSAGE: 'Rapporterad kommentar',
  ADMIN_COMMENTS: 'Admin kommentarer',
  ADD_COMMENT: 'Lägg till kommentar',
  ADD_MESSAGE: 'Kommentar',
  ADD_NAME: 'Namn',
  REPORTER_NAME: 'Rapportör',
  ERROR_BOTH_FIELDS: 'Kommentar måste vara ifyllda',
  INVOICE_MESSAGE: 'Fakturameddelande',
  INVOICE_MESSAGE_PLACEHOLDER: `Skicka meddelande kopplat till din faktura.
Ej obligatoriskt. Max antal tecken: 255`,
  COMPANY: 'Företag',
  ORGANIZATIONNUMBER: 'Org. nr.',
  STREAMING_MINUTES: 'Streamade minuter',
  RECORDING_MINUTES: 'Inspelade minuter',
  MIN_SPENT: 'min spenderade av',
  MIN_AVAILABLE: 'min tillgängliga denna period',
  UNLIMITED_STREAMING: 'Obegränsad streaming',
  UNLIMITED_RECORDING: 'Obegränsad inspelning',
  RECORDING_UNAVAILABLE: 'Inspelning är inte tillgänglig',
  CREDIT_CARD: 'Betalkort',
  YES: 'Ja',
  NO: 'Nej',
  TRAINER_MESSAGES: [
    {
      title: 'Vill du spara tid och jobba smartare?',
      text: [
        'Gör som Pytt Hamberg, Rebecka Mauléon & Camilla Kalseth – använd Pocket Office!',
        'Fakturering, automatiska betalningspåminnelser och all information om dina träningar och kunder sparat på ett ställe.',
        'Effektivt, enkelt & ökad kontroll!',
      ],
      link: 'https://www.ridesum.se/tranare/',
      linktitle: 'Läs mer här!',
    },
    {
      title: 'Tips vid digital träning:',
      text: [
        '1. Zooma med hjälp av plustecknet nere till höger på skärmen.',
        '2. Vill du visa något? Klicka på kameraikonen för att starta din kamera. Ryttaren behöver klicka på ”tränarikonen”.',
        '3. Maximera fönstret – klicka på "fyrkanten".',
      ],
      link: 'http://support.ridesum.com/',
      linktitle: 'Läs om fler tips på vår supportsida',
    },
  ],
  RATE_YOUR_SESSION: 'Betygsätt ditt pass',
  SESSION_DETAILS: 'Detaljer om passet',
  HOW_WAS_THE_VIDEO_QUALITY: 'Hur upplevde du videokvaliteten?',
  HOW_WAS_THE_SOUND_QUALITY: 'Hur upplevde du ljudkvaliteten?',
  ENTER_YOUR_COMMENT: 'Ange din kommentar (valfritt)',
  DATE: 'Datum',
  TRAINING_TYPE_LABEL: 'Träningstyper',
  ESTABLISHING_CONNECTION: 'Ansluter...',
  TRAINER_STREAM: {
    NOBODY_CONNECTED: 'Ingen ansluten till träningen',
    NOBODY_CONNECTED_INSURANCE: 'Ingen ansluten',
    CUSTOMER: 'Kund',
    MESSAGE_TO_CUSTOMER: 'Meddelande till kund',
  },
  TRAINER_PRE_CALL: {
    TITLE: 'Kontrollera dina inställningar innan du ansluter',
    MICROPHONE: 'Mikrofon',
    CAMERA: 'Kamera',
    BACK_CAMERA: 'Bakre kamera',
    FRONT_CAMERA: 'Främre kamera',
    CONNECTIVITY_TEST: 'Anslutningstest',
    AUDIO_QUALITY: 'Ljudkvalitet',
    VIDEO_QUALITY: 'Videokvalitet',
    JOIN_SESSION: 'Anslut',
    EXCELENT_QUALITY: 'Utmärkt',
    GOOD_QUALITY: 'Bra',
    FAIR_QUALITY: 'Medium',
    POOR_QUALITY: 'Dålig',
    BAD_QUALITY: 'Lägsta möjliga',
    ERRORS: {
      CAMERA_DISABLED:
        'Vänligen tillåt åtkomst till kamera och mikrofon för sajten app.ridesum.com och ladda därefter om denna sidan för att starta din session. Lycka till med träningen!',
      NO_CAMERA:
        'Dubbelkolla så att mikrofonen och/eller kameran är ansluten till datorn, prova därefter att ladda om sidan för att starta din träning. ',
      DEVICE_IN_USE:
        'Det ser ut som att kameran och/eller mikrofonen används av en annan webbläsarflik eller applikation. Försök att stänga andra flikar eller starta om datorn.',
      CONSTRAINTS_NOT_FOUND:
        'Det gick inte att hitta kamera och/eller mikrofon som lämpar sig för sessionen. Prova att använda en annan webbläsare.',
    },
  },
  FOOTER: {
    HOME: 'HEM',
    TRAINER: 'TRÄNARE',
    RIDER: 'RYTTARE',
    NEWS: 'NYHETER',
    SUPPORT: 'SUPPORT',
  },
  ADMIN: {
    EDIT_USER: {
      GRANT_PREMIUM: 'Grant premium',
      PREMIUM_PRODUCT: 'Premium product',
      PREMIUM_UNTIL: 'Premium until (inclusively)',
      GRANT_UNTIL: '%{product} until %{date}',
    },
    COURSES: {
      COURSES_PAYMENTS: 'Courses payments',
      FILTER_BY_USER: 'Filter by user',
      GIVE_COURSE: 'Give course',
      NO_COURSES_FOUND: 'No courses found',
      RIDER_OWNS_ALL_COURSES: 'Rider owns all courses',
      PAYMENT_DETAILS: 'Course payment details',
      PAYMENT: 'Payment',
      CUSTOMER: 'Customer',
      COURSE: 'Course',
      PAYMENT_TYPE: 'Payment type',
      STRIPE_PAYMENT: 'Stripe payment intent',
      CHARGED_PRICE: 'Charged',
      PROMOCODE: 'Promocode',
      SERVICE_FEE: 'Service fee',
      PAYMENT_START: 'Payment began',
      PAYMENT_UPDATE: 'Last update',
      PAYMENT_COMPLETE: 'Paid',
      WATCHED_LESSONS: 'Watched lessons',
      GIFT_CARD: 'Gift card',
      GIFT_CARD_CODE: 'Activation code',
      GIFT_CARD_BUYER: 'Gift card buyer',
      FREE: 'Free',
      SWISH_PAYMENT_ATTEMPTS: 'Swish payment attempts',
      PAYMENT_STATUS: {
        DONE: 'Paid (user can watch the course)',
        FAIL: "Failed (payment didn't went through)",
        NEW: 'New (payment not confirmed)',
        WAIT: 'Processing',
      },
    },
  },
  SEARCH_SCREEN: {
    SEARCH_YOUR_TRAINER: 'Hitta din tränare',
    ENTER_TRAINER_NAME: 'Sök tränare/expert med namn',
    SHOW_RESULTS_NEAR: 'Visa resultat nära mig först',
    HIGHLIGHTED_TRAINERS: 'Utvalda tränare',
    MORE_TRAINERS: 'Mer tränare/experter',
    COUNTRIES: 'Länder',
    DISTRICTS: 'Distrikt',
    LANGUAGES: 'Språk',
    COMPETENCES: 'Kompetenser',
    CATEGORIES: 'Kategorier',
    SEE_PROFILE: 'Se profil',
    BOOK_TRAINER: 'Boka tränare/expert',
    PRICE: 'Pris',
    LENGTH_AND_TRAINING_PRICE: 'Längd och pris',
    SKILLS: 'Skills',
    PER_45_MIN: 'Per 45 minuter',
    PER_X_MIN: 'Per %{minutes}',
    SEARCH: 'Sök',
    MORE: '+fler',
    SHOW_MORE: 'Visa mer',
    LIABILITY: 'Ansvarsförsäkring',
    TAX_CERTIFICATE: 'F-Skatt',
    UNKNOWN: 'Ej angivet',
    HIDE: 'Dölj',
    SHOW_ALL: 'Visa alla',
    TRAINER_EXPERT: 'Tränare/Expert',
    HAVE_NO_WRITTEN: 'Tränaren har inte angett någon beskrivning',
    FILTER: 'Filter',
    NO_CURRENCY_FILTER: 'Valfri valuta',
    CLEAR_FILTER: 'Rensa filtret',
    CLEAR_FILTER_CONFIRMATION: 'Är du säker på att du vill rensa filtret?',
    CLEAR_ALL_FILTERS: 'Rensa alla filter',
    CLEAR_ALL_FILTERS_CONFIRMATION: 'Är du säker på att du vill rensa alla filter?',
  },
  CATEGORY_SCREEN: {
    VIDEOS: 'Inspirationsfilmer',
    ERROR_MESSAGE: 'Något gick fel och vi kunde inte hämta någon kategoriinformationen. Var god försök igen.',
  },
  SEARCH_RESULT_SCREEN: {
    TRAINERS: 'Sökresultat',
    NO_RESULTS: 'Tyvärr, hittade vi ingen tränare som matchade, men någon av dessa topptränare kanske passar?',
  },
  TRAINER_PROFILE_SCREEN: {
    ERROR_MESSAGE: 'Något gick fel och vi kunde inte hämta någon tränarinformation. Var god försök igen.',
  },
  WEB_TRAINERS: {
    HEADER: {
      HOME: 'HEM',
      TRAINERS: 'TRÄNARE',
      TRAINERS__RIDESUM_FOR_RIDERS: 'RIDESUM FOR TRÄNARE',
      TRAINERS__POCKET_OFFICE: 'POCKET OFFICE',
      TRAINERS__TUTORIALS: 'INSTRUKTIONSFILMER',
      TRAINERS__MARKETING_MATERIAL: 'MARKNADSFÖRING',
      TRAINERS__ACTIVATE_PROMOCODE: 'AKTIVERA PROMOKOD',
      RIDERS: 'RYTTARE',
      RIDERS__RIDESUM_FOR_RIDERS: 'RIDESUM FOR RYTTARE',
      RIDERS__RIDER_PREMIUM: 'RYTTARPREMIUM',
      RIDERS__TUTORIALS: 'INSTRUKTIONSFILMER',
      RIDERS__ACTIVATE_PROMOCODE: 'AKTIVERA PROMOKOD',
      RIDERS__BOOK_TEST_TRAINING: 'BOKA TESTTRÄNING',
      NEWS: 'NYHETER',
      SUPPORT: 'SUPPORT',
      SUPPORT__RIDESUM_SUPPORT: 'RIDESUM SUPPORT',
      SUPPORT__CONTACT_US: 'KONTAKTA OSS',
      SUPPORT__Q_AND_A: 'FRÅGOR & SVAR',
      BOOK_AND_CONNECT: 'BOKA TRÄNARE',
      LOGIN_TRAINER: 'LOGIN TRÄNARE',
      LANGUAGE: 'SPRÅK',
      LANGUAGE_EN: 'ENGLISH',
      LANGUAGE_SV: 'SVENSKA',
    },
  },
  CARD_VIDEO: {
    REDIRECT_CONFIRMATION:
      'Videodags!\nDu kan se videos i vår mobilapp. Om du inte har den ännu skickas du vidare till App Store/Google Play för gratis nedladdning. Trevlig tittning! ',
  },
  DIGITAL_COURSES: {
    LEARN_MORE: 'Läs mer',
    HOURS: 'timmar',
    HOURS_SHORT: 'h',
    LESSONS: 'lektioner',
    MINUTES: 'minuter',
    EXERCISES: {
      one: '%{count} övningshäfte',
      other: '%{count} övningshäften',
    },
    START_COURSE: 'Starta kurs',
    CONTINUE_COURSE: 'Fortsätt kurs',
    LESSON: 'Lektion',
    LESSON_OF: 'av',
    NEW_COURSE_BANNER_CLICK_HERE: 'Läs mer här!',
    SEE_YOUR_COURSES: 'Se dina köpta kurser här!',
    HEADER: {
      OUR_COURSES: 'Våra kurser',
      GIFT_CARD: 'Presentkort',
      CONTACT_US: 'Kontakta oss',
      MY_ACCOUNT: 'Mina kurser',
      REGISTER: 'Registrera',
      LOGIN: 'Se din kurs',
      LOGOUT: 'Logga ut',
      ADDITIONAL_INFO: 'Logga in för att se köpta kurser',
    },
    FOOTER: {
      MAIN_INFO_TEXT:
        'Ridesum hjälper dig att nå din fulla potential genom att samla experter, träning och kunskap på ett ställe.',
      SERVICES: 'Tjänster',
      OUR_COURSES: 'Våra kurser',
      ABOUT: 'Om',
      CONTACT_US: 'Kontakta oss',
      FOLLOW_US: 'Följ oss',
      COPYRIGHT: 'Copyright © 2022. Ridesum. Alla rättigheter förbehållna.',
    },
    COURSES_LIST: {
      VIEW_ALL_COURSES: 'Se alla kurser',
      VIEW_FEWER_COURSES: 'Se färre kurser',
    },
    HOME_SCREEN: {
      NAME: 'Hem',
      AVAILABLE_COURSES: {
        MAIN_TITLE: 'Lär av de bästa och nå nästa nivå!',
      },
      ADDITIONAL_INFO: {
        EXCLUSIVE_COURSES_TITLE: 'Exklusiva onlinekurser',
        EXCLUSIVE_COURSES_DESCRIPTION: 'Lär dig av några av Sveriges främsta tränare och experter. ',
        PROFESSIONAL_KNOWLEDGE_TITLE: 'Proffsens kunskaper',
        PROFESSIONAL_KNOWLEDGE_DESCRIPTION:
          'Under kursens videolektioner får du djupare kunskap genom teori och praktiska övningar. ',
        TIME_AND_PLACE_TITLE: 'När och var du vill',
        TIME_AND_PLACE_DESCRIPTION:
          'Gå kursen i din egen takt, när det passar dig och repetera så många gånger du vill.',
      },
      SPECIAL_COURSES: {
        TOP_TITLE: 'Gå med i Clayton Hicks-kurser',
        MAIN_TITLE: 'Clayton Hicks',
      },
      GET_STARTED: {
        TOP_TITLE: 'VÄLJ DIN KURS',
        MAIN_TITLE: 'Ta nästa steg i din utveckling',
        MAIN_INFORMATION:
          'Ta din ridning och hästkunskap till nästa nivå! Lär av proffsen och ta del deras gedigna kunskap och bästa tips. ',
      },
      POPULAR_COURSES: {
        TOP_TITLE: 'Prova de mest populära kurserna',
        MAIN_TITLE: 'Populära kurser',
      },
      LATEST_COURSES: {
        TOP_TITLE: 'Vi har nya saker ute',
        MAIN_TITLE: 'Senaste kurserna',
      },
      REVIEWS: {
        TITLE: 'Vad säger deltagarna?',
        DESCRIPTION: 'Så här säger några av våra kursdeltagare.',
      },
    },
    SPECIFIC_COURSE_SCREEN: {
      NAME: 'Kurs',
      BUY_COURSE: 'Köp nu',
      BUY_GIFT_CARD: 'Köp presentkort',
      ACTIVATE_GIFT_CARD: 'Lös in presentkort',
      INSTRUCTOR: 'Tränare',
      ABOUT_INSTRUCTOR: 'Om instruktören',
      OVERVIEW: {
        DESCRIPTION_TITLE: 'Översikt',
        GOALS_TITLE: 'Vad du kommer att lära dig',
      },
      PRICE_AND_SUMMARY: {
        PURCHASED_COURSE_TITLE: 'Köpt kurs',
        INCLUDING_ATTACHMENTS: 'Inklusive nedladdningsbara övningar',
      },
      EPISODES: {
        TITLE: 'Kursens lektioner',
        LESSON_TITLE: 'Lektion',
      },
      REVIEWS: {
        TITLE: 'Vad säger deltagarna?',
        DESCRIPTION: 'Så här säger några av våra kursdeltagare.',
      },
      RELATED_COURSES: {
        TOP_TITLE: 'Baserat på kursen du tittar på',
        MAIN_TITLE: 'Relaterade kurser',
      },
    },
    CHECKOUT: {
      NAME: 'Betalning',
      COURSE_TITLE: 'Din kurs',
      PAYMENT_TITLE: 'Din information',
      PAY_NOW: 'Betala nu',
      PROMOCODE: 'Rabattkod',
      STRIPE_PAYMENT_METHODS: 'Kort, Google Pay och Apple Pay',
      SWISH_DESCRIPTION:
        'Swish kommer att öppnas automatiskt för att bekräfta din betalning. Om Swish inte är installerat, vänligen scanna QR koden med Swish.',
      REGISTER: {
        REQUIRED_FIELD: 'Obligatoriskt',
        INVALID_EMAIL: 'Ogiltig e-postadress',
        NOT_CONFIRMED_EMAIL: 'E-post är inte bekräftat',
        INVALID_NAME: 'Ogiltigt namn',
        MESSAGE: 'Vi behöver e-postadress och namn först. Lösenordet till ditt konto skickas till din e-post.',
      },
      ERROR: {
        NO_SUCH_COURSE: 'Kursen finns inte. Kontrollera att webbadressen stämmer.',
        ALREADY_BOUGHT: 'Du har redan köpt kursen.',
        PROCESSING_PAYMENT: 'Vi behandlar din betalning. Vänligen vänta.',
        UNKNOWN_ERROR: 'Ett oväntat fel uppstod.',
        INVALID_PROMOCODE: 'Ogiltig kampanjkod',
        SWISH_PAYMENT_ALREADY_IN_PROGRESS:
          'En betalning är redan aktiv, vänligen godkänn eller avbryt betalningen i Swish',
        SWISH_TRANSACTION_DECLINED: 'Betalning misslyckad',
        SWISH_BANKID_CANCELED: 'Verfiering via BankID avbruten',
        SWISH_BANK_ERROR: 'Fel vid betalningen',
        PAYMENT_DECLINED: 'Betalning avbruten av användaren',
        SWISH_PAYMENT_REQUEST_EXPIRED: 'Betalningen har löpt ut, vänligen försök igen',
        INVALID_GIFT_CARD_CODE: 'Presentkortets kod är ej giltig. Vänligen kontrollera den och prova igen',
        GIFT_CARD_EXPIRED: 'Presentkortets giltighetstid har passerat. Kortet kan inte användas längre',
        GIFT_CARD_ALREADY_ACTIVATED: 'Detta presentkort har redan lösts in',
      },
      PURCHASE_RESULT: {
        SUCCESS: {
          TITLE: 'Köpet är klart!',
          BODY: 'Tack för betalningen, vi har skickat informationen om ditt köp till din e-post',
          NOTE_TITLE: 'Notera: ',
          NOTE_BODY:
            'Om du redan har ett Ridesum-konto loggar du in med det för att se kursen. Har du inte något Ridesum-konto sedan tidigare, vänligen se din e-post för inloggningsuppgifter.',
        },
        PROCESSING: {
          TITLE: 'Betalningen behandlas. Vänligen vänta.',
          BODY:
            'Betalningen behandlas fortfarande. Vänligen vänta.\nNär det är klart skickar vi informationen om ditt köp till din e-post',
        },
        FAILURE: {
          TITLE: 'Hoppsan!',
          BODY: 'Tyvärr gick inte betalningen igenom. Var god försök igen.',
        },
        WAITING_SWISH: {
          TITLE: 'Bekräfta betalning i Swish',
          SCAN_QR: 'Vänligen scanna QR koden med Swish',
        },
      },
    },
    WATCH: {
      LESSONS_LIST: 'Kursens lektioner',
      BACK_TO_COURSE_PREVIEW: 'Tillbaka till tidigare sida',
      LESSON_NUMBER: 'Lektion %{number}',
      TEXT_INFORMATION: 'Lektionens innehåll',
      DOWNLOAD_MATERIALS: 'Ladda ned kursmaterial',
      COMPLETED_LESSON: 'Klar',
      NOT_COMPLETED_LESSON: 'Inte klar',
      PREVIOUS_LESSON: 'Tidigare lektion',
      NEXT_LESSON: 'Nästa lektion',
    },
    PROFILE_SCREEN: {
      NAME: 'Mina kurser',
      GREETINGS: 'Hej',
      EXPLANATORY_TEXT: 'Här är de kurser som du tittar på.',
      COMPLETED_COURSES: 'Genomförda kurser',
      ACTIVE_COURSES: 'Aktiva kurser',
      TRAINING_HOURS: 'Din utbildningstid',
      ALL: 'Alla',
      ACTIVE: 'Aktiva',
      COMPLETED: 'Genomförda',
      NOT_STARTED: 'Ej påbörjade',
      PURCHASED_COURSES: {
        TOP_TITLE: 'Här har vi samlat alla dina kurser',
        MAIN_TITLE: 'Dina pågående kurser',
      },
    },
    GIFT_CARD_OVERVIEW: {
      TITLE: 'Ge en hästkurs med Sveriges topptränare!',
      GIFT_CARD: 'Presentkort',
      PAGE_DESCRIPTION: `Köp ett presentkort till en hästälskande vän – en uppskattad gåva! Har du fått ett presentkort? Lös in det här och starta valfri kurs idag. 

I Ridesums digitala kurser delar Sveriges skickligaste tränare och experter med sig av sina bästa tips och övningar. 

En kurs är ca 2 timmar och innehåller 6 unika videolektioner samt nedladdningsbara övningar. 

Mottagaren kan gå kursen hur många gånger den vill under ett år, när som helst och i sin egen takt.`,
      INSTRUCTIONS_HEADER: 'Hur funkar det?',
      INSTRUCTIONS: {
        TITLE_1: '1. Köp ett presentkort',
        TEXT_1: 'Du får ett fint presentkort att skriva ut eller skicka vidare till mottagaren.',
        TITLE_2: '2. Ge bort',
        TEXT_2: 'Gäller för valfri kurs under ett år.',
        TITLE_3: '3. Aktivera och välj kurs',
        TEXT_3: 'Din vän löser in presentkortet och väljer kurs. Sedan är det bara att börja!',
      },
      WHAT_OTHERS_THINK: 'Vad tycker våra kursdeltagare?',
      QUOTE_TEXT: '”Är helt överväldigad! Så pedagogiskt och inspirerande. Toppenbra kurser!”',
      QUOTE_AUTHOR: '- Gun, Stockholm',
      WHATS_RATING: 'Vad är ditt helhetsintryck av kursen?',
      POPULAR_COURSES: 'Populära kurser',
      GIFT_TO_HORSE_LOVER: 'Gör en hästvän glad – ge en kurs!',
    },
    GIFT_CARD_CHECKOUT: {
      BUY_PART_1: 'Köp ett presentkort för',
      BUY_PART_2: 'Du får presentkort och aktiveringskod skickad till din e-post direkt.',
      ACTIVATE_CODE: 'Aktivera presentkortskod',
      ACTIVATION_INSTRUCTIONS:
        'Din vän löser in* presentkortet med koden och väljer kurs. Sedan är det bara att börja!',
      REMARK: '*Presentkortet är giltigt 1 år fr.o.m. inköpsdatumet.',
      GIFT_CARD_WITH_PRICE: '%{price} presentkort',
      GIFT_CARD_INSTRUCTIONS:
        'Presentkortet skickas till din e-post. Din vän löser in det och kan sedan se valfri Ridesum-kurs kostnadsfritt.',
      MATERIALS: 'Övningar',
      POSSIBLE_COURSE: 'Valfri kurs',
      POSSIBLE_COURSE_WITH_TRAINER: 'Valfri kurs med någon av Sveriges bästa tränare & experter!',
    },
    GIFT_CARD_REDEEM: {
      REDEEM_COURSE: 'Aktivera kurs',
      ENTER_CODE: 'Ange koden på presentkortet här',
      INSTRUCTIONS:
        'Efter du har löst in ditt presentkort kan du titta på kursen <b>%{courseTitle}</b> via ditt Ridesum ryttarkonto. Instruktioner skickas till din e-post.',
      SELECT_COURSE: 'Välj en annan kurs för att aktivera',
      CODE_IS_VALID: 'Koden är giltig',
    },
    GIFT_CARD_REDEEMED: {
      ACTIVATED: 'Presentkortskod aktiverad',
      INSTRUCTIONS: 'Du har aktiverat presentkortet! Instruktioner skickas till din e-post.',
    },
    RESET_PASSWORD: {
      CREATE_PASSWORD: 'Skapa lösenord för ditt Ryttarkonto hos Ridesum',
      CONFIRM_PASSWORD: 'Bekräfta lösenord',
      PASSWORDS_DONT_MATCH: 'Lösenorden överensstämmer inte',
      LINK_EXPIRED: 'Denna länken är inte längre tillgänglig. Du kan beställa en ny länk som skickas till din e-post.',
      LINK_RESENT: `En ny länk är skickad, Vänligen kolla i din inbox.
Du kan stänga detta fönster nu`,
      SEND_NEW_LINK: 'Skicka ny länk',
    },
  },
  COMMON_FOOTER: {
    QUICK_LINKS: 'SNABBLÄNKAR',
    TRAINER: 'Tränare',
    RIDER: 'Ryttare',
    DIGITAL_COURSES: 'Digitala kurser',
    SEARCH_TRAINER: 'Sök tränare',
    NEWS: 'Nyheter',
    INSTRUCTIONAL_VIDEOS: 'Instruktionsfilmer',
    CONTACT: 'Kontakt',
    SUPPORT_WITH_RIDESUM: 'Support med Ridesum',

    ABOUT_RIDESUM: 'OM RIDESUM',
    JOB: 'Jobb',
    TEAM: 'Team',
    STORY: 'Storyn',
    MISSION: 'Mission',
    PRIVACY_POLICY: 'Integritetspolicy',
    TERMS_OF_USE: 'Användarvillkor',
    COPYRIGHT: '© 2022 Ridesum',

    SUBSCRIBE_LABEL: 'Subscribe',
    INDICATES_REQUIRED_LABEL: 'indicates required',
    EMAIL_LABEL: 'Email',
    LANGUAGE_LABEL: 'Language',
    RIDER_OR_TRAINER_LABEL: 'Are you rider or trainer/coach?',
    CAPTCHA_LABEL: 'captcha',

    SUBSCRIPTION_ERRORS: {
      REQUIRED_FIELD: 'This field is required.',
      INVALID_EMAIL: 'Please enter a valid email address.',
    },
  },
  RIDER_STREAM: {
    NO_MINUTES_LEFT: 'Alla streamingminuter förbrukade',
    MINUTES_EXPIRED:
      'Det finns risk för att dina streamingminuter tar slut under passet. Uppgradera ditt abonnemang via din Profil i menyn. Lycka till på träningen!',
    MINUTES_EXPIRED_DURING_SESSION:
      'Du kan träna färdigt idag, men tänk på att du behöver uppgradera ditt abonnemang till nästa pass för att få mer streamingminuter. Det gör du under Profil.',
    UPGRADE_TO_PREMIUM: 'Uppgradera till Premium',
    UPGRADE_TO_PREMIUM_DESCRIPTION: 'Inspelningsvideo är tillgänglig för premiumkonto.',
    READ_MORE: 'Läs mer',
    TRAINING_IS_PAUSED: 'Passet är pausat',
    CONTINUE_TRAINING: 'Fortsätt passet',
    FINISH_TRAINING: 'Avsluta passet',
    HAVING_PROBLEMS_WITH_STREAMING: 'Har du problem med streamingen?',
    OPEN_IN_CHROME: 'Öppna i Chrome',
    TRAINING_IS_FINISHED: 'Träningen avslutad',
    MAY_CLOSE_WINDOW_NOW: 'Du kan nu stänga detta fönster och gå tillbaka till Ridesum',
    RETURN_TO_RIDESUM: 'Tillbaka till Ridesum',
  },
  ANALYSES: {
    TRY_AISA_NOW: 'Testa AI Seat Analytics nu',
    TRY_AIHA_NOW: 'Testa AI Horse Analytics nu',
  },
  GPS_TRACKER: {
    RIDING_HORSE: 'rider %{horseName}',
    OFFLINE: 'off-line',
    SPEED_KPH: '%{speedKph} km/h',
    NO_UPDATES: {
      TITLE: 'Inga platsuppdateringar',
      MESSAGE:
        'Det har inte tagits emot några nya platsuppdateringar på ett tag. Internetanslutningen kan vara tillfälligt otillgänglig eller så har appen stängts av.',
    },
    FINISHED: {
      TITLE: 'Spårningen är avslutad',
      MESSAGE: 'Du kan stänga denna sida nu.',
    },
  },
  ERROR_BOUNDARY: {
    APP_CRASHED: 'Appen kraschade',
    WE_WILL_PROCESS_PROBLEM: 'Vi jobbar på att lösa problemet.',
    YOU_CAN_TRY_AGAIN: 'Du kan försöka uppdatera sidan.',
  },
  WEB_MEETING: {
    CREATE_LINK: 'Skapa möteslänk',
    CREATE: {
      TITLE: 'Nytt möte',
      DATE: 'När',
      EMAIL: 'Kundens e-post',
      NAME: 'Kundens namn',
      PHONE: 'Kundens telefonummer',
      ADDRESS: 'Kundens adress',
      CREATE: 'Skapa',
    },
    RESULT: {
      TITLE: 'Länken är klar',
      COPY: 'Kopiera till urklipp',
      COPIED: 'Kopierad!',
      BACK: 'Gå tillbaka',
    },
    PRE_CALL: {
      WILL_BE_RECORDED: 'Hela mötet kommer att spelas in',
    },
    FINISHING: {
      TITLE: 'Lämnar mötet',
      MESSAGE: 'Vill du lämna mötet nu?',
    },
    FINISHED: {
      TITLE: 'Mötet är avslutat',
      MESSAGE: 'Du kan stänga sidan nu',
    },
  },
};
